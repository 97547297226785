.confirmation-wrapper {
  .popup-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    opacity: 0.8;
    background-color: var(--popup-background);
    z-index: 1;
  }
  
  .notifications {
      min-width: 250px;
      padding: 2rem 1rem;
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    
      font-size: 18px;
      line-height: 20px;
      
      color: var(--err);
      background-color: var(--notifications-info-background);
      border: 2px solid var(--err);
      border-left-width: 6px;
      border-radius: 6px;
      z-index: 2;
    
    .notification {
      margin: 2px 9px 0 0;
      text-align: center;
      word-wrap: break-word;
    }

    .yes-no-wrapper {
      width: 69%;
      display: flex;
      justify-content: space-between;
      margin: 18px auto 0;

      .button-yes,
      .button-no {
        display: inline-block;
        padding: 8px 13px;

        color: var(--confirm-button);
        background-image: linear-gradient(-180deg,var(--confirm-gradient-top) 0,var(--confirm-gradient-button));
        font-size: 14px;
        font-weight: 700;
        text-shadow: 0 1px 3px var(--button-shadow);
        text-transform: uppercase;
        border: 3px solid var(--links-top-hover);
        border-radius: 2rem;
        cursor: pointer;

        &:hover {
          color: #303434;
          border-color: #babdbe;
          background: #fff;
          background-image: linear-gradient(-180deg,#fff 0,#e1e3e3);
          box-shadow: inset 0 -1px 0 0 #babdbe,0 1px 4px 0 rgba(0,0,0,.1);
        }
      }

      .button-yes,
      .button-yes:hover {
        color: var(--err);
        border-color: var(--err);
      }
    }

  }

}