.add-assist-wrapper {
  margin-top: 1rem;

  .add-assist-title {
    font-weight: 700;
  }

  select {
    width: 200px;
    padding: 7px;
    margin: 10px 0;
    
    font-size: 1rem;
    background-color: var(--text-general);
    outline: none !important;
    border:1px solid var(--input-focus-border);
  }

  .assist-set-rep-wrapper {
    display: flex;
    align-items: center;

    .assist-repCount {
      margin-left: 1rem;
    }

    .add-button {
      display: flex;
      align-items: center;
      padding: 13px 15px;
      margin-left: 1rem;

      font-size: 18px;
      font-weight: 700;
      color: var(--text-general);
      background: var(--notifications-info);
      text-shadow: 0 1px 3px var(--add-button-shadow);
      border-radius: 4px;
      cursor: pointer;

      &:hover {
        transform: translateY(-1px);
        background: var(--add-button-shadow-hover);
        box-shadow: inset 0 -1px 0 0 #babdbe,0 1px 4px 0 rgba(0,0,0,.1);
      }
    }
  }
}


@media (min-width: 600px) {
  .add-assist-wrapper {
    width: 300px;
    margin: 1rem auto 0;
  }

  #workout-home {
    .add-assist-wrapper {
      margin-top: 3rem;
    }
  }

}