#nav-workout {
  margin: 12px 0;
  display: flex;
  justify-content: space-evenly;

  button {
    padding: 8px 13px;

    color: var(--nav-button);
    background-color: var(--nav-button-background);

    font-size: 14px;
    font-weight: 700;
    text-shadow: 0 1px 3px var(--button-shadow);
    text-transform: uppercase;
    border: 3px solid var(--nav-button-border);
    border-radius: 2rem;
    cursor: pointer;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    }
  }
  
}