.moveRight-enter {
  opacity: 0.01;
  transform: translate(-200px, 0)
}

.moveLeft-enter {
  opacity: 0.01;
  transform: translate(200px, 0)
}

.moveRight-enter-active,
.moveLeft-enter-active {
  opacity: 1;
  transform: translate(0, 0);
  transition: all 300ms ease-in;
}


@keyframes slideUp {
  0% {
    transform: translateY(100%);
  }
  100% {
    transform: translateY(0);
  }
}

.fade-in-red {
  opacity: 1;
  animation: .3s ease-in 0s 1 fadeInOpacity-red;
}

.fade-in-blue {
  opacity: 1;
  animation: .3s ease-in 0s 1 fadeInOpacity-blue;
}

@keyframes fadeInOpacity-red {
  0% {
    opacity: .65;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeInOpacity-blue {
  0% {
    opacity: .65;
  }
  100% {
    opacity: 1;
  }
}
