.exercise-wrapper {
    
  .exercise-title-wrapper {
    display: flex;
    align-items: center;
    margin-bottom: 7px;

    .bullet {
      display: inline-block;
      width: 15px;
      height: 15px;
      margin-right: 5px;

      background-color: var(--bullet);
      transform: rotate(45deg)
    }
    .exercise-title {
      display: inline-block;

      text-shadow: 0 1px 3px var(--exercise-title-shadow);
      font-weight: 700;
    }
  }

  .one-rm-tm-wrapper {
    margin-left: 20px;

    .exercise-one-rm {
      margin-bottom: 7px;
      div {
        text-shadow: 0 1px 3px var(--exercise-title-shadow);
      }
    }
    .exercise-tm {
      div {
        text-shadow: 0 1px 3px var(--exercise-title-shadow);
      }
    }
  }
}