.scheme-wrapper {
  margin: 0 1.1rem 1rem;

  #schemeChange {
    display: inline-block;
    padding: 8px;

    background-color: var(--scheme);
    text-shadow: 0 1px 3px var(--button-shadow);
    border: 1px solid var(--nav-button-border);
    border-radius: 2rem;
    cursor: pointer;

    &:hover {
      transform: translateY(-1px);
      box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
    }
  }
}