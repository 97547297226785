#background-gradient {
  position: absolute;
  width: 100%;
  height: 100%;

  background: linear-gradient(150deg,var(--gradient-top) 15%,var(--gradient-mid) 70%,var(--gradient-bottom) 94%);
  transform: skewY(-12deg);
  transform-origin: 0;

  span {
    position: absolute;
    height: 40px
  }

  span:first-child {
    width: 50%;
    left: 50%;
    top: 40px;

    background: var(--stripe-one);
  }

  span:nth-child(2) {
    width: 72%;
    height: 150px;
    left: 0;
    top: 163px;

    background:var(--stripe-two);
  }

  span:nth-child(3) {
    width: 25%;
    height: 90px;
    left: 0;
    bottom: 0;

    background: var(--stripe-three);
  }

  span:nth-child(4) {
    width: 14%;
    right: 0;
    bottom: 40px;

    background: var(--stripe-four);
  }

  span:nth-child(5) {
    width: 25%;
    right: 0;
    bottom: 0;

    background: var(--stripe-five);
  }
}