#home-logged-in {
  margin: 0 1rem 3rem;

  #workout-home {
    margin-top: 0;
  
    color: var(--workout-text);
    text-shadow: 0 1px 3px var(--workout-text-shadow);
    font-size: 19px;
  
    .logged-in {
      color: var(--text-general);
      font-size: 16px;
      word-wrap: break-word;
  
      .user-email {
        margin-top: 3px;
  
        font-weight: 700;
      }  
    }
  
    .input-title {
      margin-bottom: 2px;
    }
  
    input {
      width: 55px;
      padding: 7px;
  
      font-size: 1rem;
      border: 1px solid var(--input-border);
    }
    input:focus {
      outline: none !important;
      border:1px solid var(--input-focus-border);
      box-shadow: 0 0 10px var(--input-box-shadow);
    }
  
    .add-assist-wrapper {
      margin-top: 3rem;
    }

    #workout-session {
      margin: 5rem 0;
  
      input {
        width: 30px;
      }

      .exercise-wrapper {
        font-size: 16px;
      
        .exercise-title {
          margin: 7px 0;
      
          font-size: 30px;
          font-weight: 700;
          text-shadow: 0 1px 3px rgba(215, 217, 255, 0.8);
          text-align: center;
        }
      
        .title-row {
          display: grid;
          align-items: center;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
          padding-bottom: 7px;
          margin-bottom: 7px;
          
          border-bottom: 3px solid var(--title-row-border);
          font-weight: 700;
        }
      
      }
  
    }
  
    #finish-options {
      .done-button {
        display: inline-block;
          padding: 20px;
      
          font-size: 18px;
          font-weight: 700;
          color: white;
          background-image: linear-gradient(-180deg, var(--notifications-info) 0, var(--done-gradient-bottom));
          border-radius: 3px;
          border: 1px solid #d7dbdb;
          box-shadow: inset 0 -1px 0 0 #d7dbdb;
          cursor: pointer;
  
          &:hover {
            transform: translateY(-1px);
            box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
          }
      }
    }
    
  }

}


@media (min-width: 600px) {
  #workout-home {
    margin-top: 0;

    .demo-step {
      max-width: 700px;
      margin: 3rem auto 2rem;
    }

    #workout-session {
      max-width: 1000px;
      margin: 5rem auto 3rem;
    }

    .assistance-wrapper {
      max-width: 700px;
      margin: 0 auto;
    }
  }
}