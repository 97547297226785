#nav-main {
  //display: flex;
  //justify-content: space-between;

  display: grid;
  grid-template-areas:
    'home link-right';
  align-items: center;

  font-size: 20px;

  a {
    padding: 1rem;
    text-shadow: 0 1px 3px var(--links-top-shadow);
    cursor: pointer;

    &:hover {
      color: var(--links-top-hover);
    }
  }

  .link-home {
    grid-area: home;
    justify-self: flex-start;
    font-weight: 700;
    cursor: pointer;
  }
  .link-login,
  .link-logout {
    grid-area: link-right;
    justify-self: flex-end;
    cursor: pointer;

    &:hover {
      color: var(--links-top-hover);
    }
  }
  .link-logout {
    padding: 1rem;
  }
  .logged-in {
    grid-area: logged-in;
    padding: 1rem;
    display: flex;

    div {
      display: block;
      word-wrap: break-word;
    }
  }
} 