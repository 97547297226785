#login {
  max-width: 80%;
  margin: 2rem auto 3rem;
  padding: 2rem 1rem 1rem;

  font-size: 16px;
  color: var(--form-text);
  background-color: var(--background);
  box-shadow: 0 15px 35px 0 rgba(60,66,87, .12), 0 5px 15px 0 rgba(0,0,0, .12);
  border-radius: 4px;

  animation: .2s ease-out 0s 1 slideUp;

  input:focus {
    outline: none !important;
    border:1px solid var(--input-focus-border);
    box-shadow: 0 0 10px var(--input-box-shadow);
  }

  h2 {
    margin-bottom: 2rem;

    font-size: 18px;
    text-align: center;
    line-height: 22px;
    word-wrap: break-word;
  }

  .form-wrapper {
    margin-bottom: 2rem;

    .form-title {
      margin-bottom: 5px;

      font-weight: 700;
    }

    input {
      width: 90%;
      min-height: 30px;
      padding-left: 7px;

      outline: 0;
      border-radius: 4px;
    }
  }

  .submit-wrapper {
    margin-bottom: 2rem;

    text-align: center;

    .submit-button {
      display: inline-block;
      padding: 15px 25px;

      font-family: inherit;
      font-size: inherit;
      font-weight: 700;
      color: var(--text-general);
      background-color: var(--form-submit);
      background: linear-gradient(var(--submit-button-top), var(--submit-button-bottom));
      box-shadow: 0 1px 1px rgba(0,0,0,.16);
      border-style: none;
      border-radius: 3px;
      cursor: pointer;

      &:hover {
        transform: translateY(-1px);
        box-shadow: 0 7px 14px rgba(50,50,93,.1), 0 3px 6px rgba(0,0,0,.08);
      }
    }
  }

  .links {
    text-align: center;

    a {
      color: var(--form-links);
    }

    .links-register {
      margin: 1rem 0;
  
      a {
        padding: 0;
  
        font-size: 16px;
        font-weight: 400;
        background: unset;
        text-shadow: none;
      }
    }

    .links-resend {
      margin-bottom: 1rem;

      line-height: 18px;
    }

  }
  
}

@media (min-width: 468px) {
  #login {
    max-width: 369px;
  }

}